import React from "react"

import Layout from "../components/layout"
import { useLocation } from '@reach/router'

import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  margin: 1rem 0.5rem;
`

const StyledH1 = styled.h1`
  font-size:2rem;
  text-align:center;
`

const StyledSubtitle = styled.p`
  font-weight: bold;
  text-align:center;
`

export default function Page() {
  const location = useLocation();
  let country ="CH"
  let lang="de-CH"
  
  const url = typeof window !=="undefined" ? window.location.href :"";


  if(url.includes(".com")){
    country ="DE"
  }


  return (

    <Layout location={country} path={location.pathname} language={lang}>
      <Wrapper>
        <StyledH1>Vielen Dank für Ihre Bestellung</StyledH1>
        <StyledSubtitle> Ihre Zahlung war erfolgreich</StyledSubtitle>
        <StyledSubtitle> Sie können diesen Tab nun schliessen.</StyledSubtitle>
       
      

      </Wrapper>
    </Layout>

  )
}
